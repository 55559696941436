.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2;
  }
  
  .display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2;
  }
  
  @media (min-width:1200px) {
    .display-3 {
      font-size: 4rem;
    }
  }
  
  @media (min-width:1200px) {
    .display-5 {
      font-size: 3rem;
    }
  }
  
  .scrolling {
    position: relative;
    top: 0;
    left: 0;
    display:inline-block; 
    white-space: nowrap;
    animation-name: bounce2;
    animation-delay: 1000ms;
    animation-duration: 8s;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-timing-function: linear ;
  }

  @keyframes bounce1 {
    0% {  transform: translateX(0px)    }
    50%   {  transform: translateX(-130%); opacity: 1; }
    51%   {  opacity: 0; }
    55%   {  transform: translateX(0px); opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes bounce2 {
    0% {  transform: translateX(0px)    }
    10% {  transform: translateX(0px)    }
    40%   {  transform: translateX(-70%) }  
    50%   {  transform: translateX(-70%) }
    90% {  transform: translateX(0px)    }
    100% {  transform: translateX(0px)    }
  }

  @keyframes bounce3 {
    
    0% {  transform: translateX(0px); opacity: 1;    }
    10% {  transform: translateX(0px); opacity: 1;    }
    50% {  transform: translateX(-100%); opacity: 1;   }
    51% {  opacity: 0; }
    52% {  transform: translateX(100%); opacity: 0;   }
    53% {  opacity: 0; }
    54% {  opacity: 1; }
    100% {  transform: translateX(0px); opacity: 1;    }
  }

  .trackTitle {
    margin-bottom: -2px;
    width: auto;
    white-space: nowrap;
  }
  
  .artistName {
    margin-bottom: -2px;
  }
  
  .trackRow {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  
  .trackImage {
    min-width: 50px;
    min-height: 50px;
    width: 25vw;
    height: 25vw;
    max-width: 125px;
    max-height: 125px;
    margin-right: 10px;
  }
  
  .trackInfoDiv {
    /* background: red; */
    width: 60%;
    height: 25vw;
    min-height: 50px;
    max-height: 125px;
    overflow: hidden;
  }
  
  .trackVoteInfoDiv {
    height: 25vw;
    min-height: 50px;
    max-height: 125px;
    padding-right: 15px;
  }
  
  .trackVoteCountBadge {
    padding-right: 15px;
    padding-left: 15px;
    width: 50px;
  }