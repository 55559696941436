.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.font-comfortaa {
  font-family: "Comfortaa, serif";
}

:root {
  --bc-width: 80vw;
  --bc-cell-max-pixels: 50px;
  --bc-cell-size: calc(var(--bc-width) / 5);
}

.ruleTitle {
  font-weight: 700!important;
  font-size: 1.15rem !important;
}

.ruleBody {
  text-align: left;
  font-size: 1.1rem !important;
}

.loader_image {
  width: calc(min(90vh,90vw,1024px));
  height: calc(min(90vh,90vw,1024px));
}
