.bingo-cell {
    border-color: black;
    border-style: solid;
    border-width: 2px;
}

.bingo-header-cell-div {
    float: left;
    border-style: none;
    /* display: flex;
    justify-content: center;
    align-items: flex-end; */
}

.bingo-cell-div {
    float: left;
    border-color: black;
    border-style: solid;
    border-width: 2px;
    margin-left: -1px;
    margin-top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.bingo-header-label {
    background-color: rgba(156, 194, 144, 0.79);
    color: #000000;
    border-style: solid;
    border-radius: 100%;
    border-color: rgba(156, 194, 144);
    border-width: 4px;
    width: inherit;
    height: inherit;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    font-size: 3.5em;
    font-weight: 700;
    margin-bottom: 5px;
}

.bingo-cell-selected {
    background-color: rgba(0, 0, 0, 0.79);
    color: #000000;
    border-style: solid;
    border-radius: 100%;
    border-color: rgb(0, 0, 0);
    border-width: 4px;
    width: inherit;
    height: inherit;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    font-size: 3.5em;
    font-weight: 700;
}